import { Asset, createClient, EntryCollection } from 'contentful'
import { ContentfulBlogPost, ClientContentfulBlogPost } from 'types/contentful'

enum ContentTypes {
  BlogPost = 'blogPost',
  Page = 'page',
}

const client = createClient({
  space: process.env.CONTENTFUL_SPACE_ID as string,
  accessToken: process.env.CONTENTFUL_ACCESS_TOKEN as string,
  environment: process.env.CONTENTFUL_ENVIRONMENT as string,
})

export interface ContentfulQuery {
  [key: string]: string | number | boolean | undefined | string[]
  content_type?: string
  limit?: number
  order?: string
}

export const getBlogPosts = async (
  query?: ContentfulQuery
): Promise<EntryCollection<ContentfulBlogPost>> => {
  const defaultQuery: ContentfulQuery = {
    content_type: ContentTypes.BlogPost,
    order: '-fields.publishedAt',
  }

  query = {
    ...defaultQuery,
    ...query,
  }

  return await client.getEntries<ContentfulBlogPost>(query)
}

export const getRelatedArticles = async (
  ignoreSlug: string,
  categories: string[]
): Promise<EntryCollection<ClientContentfulBlogPost>> => {
  const query: ContentfulQuery = {
    content_type: ContentTypes.BlogPost,
    order: '-fields.publishedAt',
    'fields.categories[in]': categories.join(','),
    'fields.slug[nin]': ignoreSlug,
    limit: 2,
  }

  return await client.getEntries<ClientContentfulBlogPost>(query)
}

export const getAsset = async (fileId: string): Promise<Asset> =>
  await client.getAsset(fileId)
